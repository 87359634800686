<template src="./ViewAllRecommendations.html">
</template>

<script>

import ProfileRecommendation from "@/components/ProfileRecommendation/ProfileRecommendation";

export default {
  name: "ViewAll",
  components: {
    ProfileRecommendation
  },
  props:{
    title: {
      type: String
    },
    data: {
      type: Array
    }
  },
  data(){
    return{
      itemsToShow: Number(2),
      typeCard: { button: "button--blue", 'progress': 'is-info' },
    }
  },
  mounted() {
    window.addEventListener('resize', this.reportWindowSize);
    this.reportWindowSize();
  },
  methods: {
    reportWindowSize() {
      if (window.innerWidth < 550) {
        this.itemsToShow = Number(1);
      } else if (window.innerWidth < 1224) {
        this.itemsToShow = Number(2);
      } else if (window.innerWidth < 1580) {
        this.itemsToShow = Number(3);
      } else if (window.innerWidth > 1580) {
        this.itemsToShow = Number(3);
      }
    },
  }
}
</script>

<style scoped lang="scss" src="./ViewAllRecommendations.scss">

</style>
